import { useEffect, useState } from 'react'
import axios from 'axios'

export const useAxiosGet = () => {
  const [data, setData] = useState()
  const [request, setRequest] = useState()
  const [message, setMessage] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)

  const fetchData = async () => {
    if (!!!request) return
    setIsError(false)
    setIsLoading(true)

    try {
      const result = await axios(request.url, request.headers && { headers: request.headers })
      setData(result.data)
    } catch (error) {
      setMessage(error.message)
      setIsLoading(false)
      setIsError(true)
    }

    setIsLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [request])

  return [{ data, message, isLoading, isError }, setRequest]
}
