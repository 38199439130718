import React, { useEffect } from 'react'
import * as cheerio from 'cheerio'
import { graphql } from 'gatsby'
import { MDXProvider } from '@mdx-js/react'
import { MDXRenderer } from 'gatsby-plugin-mdx'

import Banner from './banner'
import Layout from './layout'
import Link from './link'
import Meta from './meta'

import { useAxiosGet } from '../hooks/use-axios-get'

const GlobalComponents = {
  Banner,
  Link,
}

const GoogleDocView = ({ location, data: { mdx } }) => {
  const { body, mdxAST, slug, frontmatter } = mdx
  const { title, author, description, document, format } = frontmatter
  const hasMdxBody = mdxAST?.children.length > 1
  const googleDoc = `https://docs.google.com/document/d/e/${document}/pub?embedded=true`
  const [{ data, message, isLoading, isError }, request] = useAxiosGet()

  useEffect(() => {
    request({
      url: googleDoc,
    })
  }, [])

  const handleEmbed = () => {
    if (isError)
      return (
        <div className="google-doc-view-content">
          Something went wrong. Sorry! {message && <div>Message: {message}</div>}
        </div>
      )
    if (isLoading || !!!data) return <div className="google-doc-view-content text-uppercase">Loading...</div>
    let lineCount = 0
    const doc = cheerio.load(data)
    doc('body').css('margin', '0')
    doc('body div').css('max-width', 'none')
    doc('body div').css('padding', '0.5rem 2rem')
    doc('p span').each((i, e) => {
      if (format == 'condensed' && e.children.length == 0) {
        doc(e.parent).css('display', 'none')
      } else {
        lineCount += doc(e).find('br').length + 1
      }
    })
    doc('a').each((i, e) => {
      var url = decodeURIComponent(doc(e).attr('href'))
      const match = url.match(RegExp('^https://www.google.com/url.q=([^&]*).*'))
      const cleanUrl = match && match[1]
      doc(e).attr('href', cleanUrl)
      doc(e).attr('target', '_blank')
    })
    return (
      <div className="google-doc-view-content" style={{ height: `${lineCount * 18}px` }}>
        <iframe width="100%" height="100%" srcDoc={doc.html()} src={googleDoc}></iframe>
      </div>
    )
  }

  return (
    <Layout author={author} location={location}>
      <Meta title={title} author={author} description={description} />
      <a id="top"></a>
      <section className="main">
        <header className="medium">
          {hasMdxBody && (
            <MDXProvider components={GlobalComponents}>
              <MDXRenderer>{body}</MDXRenderer>
            </MDXProvider>
          )}
          {document && handleEmbed()}
        </header>
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query GoogleDocViewQuery($id: String) {
    mdx(id: { eq: $id }) {
      slug
      body
      mdxAST
      frontmatter {
        author
        description
        document
        format
        title
      }
    }
  }
`

export default GoogleDocView
